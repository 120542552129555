<template>
  <div style="margin-bottom: 80px;">
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-full">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            วิสัยทัศน์ พันธกิจ และเป้าหมายการพัฒนากองทุนฯ
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-8">
      <div class="cardCustom p-8">
        <div class="content1 mb-6">
          การกำหนดแนวทางการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม พ.ศ. 2562
          โดยได้ยึดถือหลักการพื้นฐานตามที่ได้เสนอก่อนหน้านี้ คือ
          ความสอดคล้องกับแผนยุทธศาสตร์ชาติ 20 ปี
          และแผนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม โดยได้กำหนดวิสัยทัศน์ พันธกิจ
          วัตถุประสงค์ กรอบนโยบาย ดังนี้
        </div>

        <div class="content2">
            วิสัยทัศน์
        </div>

        <div class="content1 mb-6">
          วิสัยทัศน์และเป้าหมายของการพัฒนากองทุนดิจิทัลเพื่อเศรษฐกิจและสังคม
          มุ่งเน้นการพัฒนาอย่างต่อเนื่องในระยะยาวอย่างยั่งยืน
          ให้สอดคล้องกับการจัดทำยุทธศาสตร์ชาติ 20 ปี และนโยบายและ
          แผนระดับชาติว่าด้วยการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
          แต่เพื่อให้แผนฯ สามารถรองรับพลวัตของเทคโนโลยีดิจิทัล
          ตามที่กำหนดในวิสัยทัศน์ คือ
          “เป็นกองทุนชั้นนำในการผลักดันและเพิ่มขีดความสามารถการพัฒนาเศรษฐกิจและสังคมไทยด้วยเทคโนโลยีดิจิทัล”
        </div>

        <div class="content mb-6">พันธกิจ</div>

        <div align="center">
          <img
            class="w-full h-full object-contain"
            style="max-width: 606px; max-height: 328px"
            src="@/assets/mog/image65.svg"
            alt=""
          />
        </div>

        <div class="mt-6 ml-8" style="margin-bottom: 85px;">
          <div class="content">
            (1) เป็นกองทุนที่สนับสนุนนวัตกรรมเพื่อสร้างความเปลี่ยนแปลง
          </div>

          <div class="content">
            (2)
            เป็นกองทุนที่มีประสิทธิภาพในการสนับสนุนนวัตกรรมและมีการบริหารจัดการที่ดี
          </div>

          <div class="content">
            (3) เป็นกองทุนที่ผู้ขอรับทุนยอมรับและน่าเชื่อถือ
            ซึ่งเน้นการสนับสนุนประโยชน์ทางดิจิทัล เพื่อสาธารณะ
          </div>

          <div class="content">
            (4) เป็นกองทุนที่ผลักดันนวัตกรรมที่ริเริ่มสร้างสรรค์สิ่งใหม่ๆ
          </div>

          <div class="content">
            (5) เป็นกองทุนที่ช่วยเติมเต็มช่องว่างต่าง ๆ
            ที่เกิดขึ้นด้วยเทคโนโลยีดิจิทัล
          </div>

          <div class="content">
            (6) เป็นกองทุนที่ให้ความรู้และร่วมพัฒนาศักยภาพคนไทย
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.content1 {
  text-indent: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}

.content2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}
</style>