<template>
  <div style="margin-bottom: 80px;">
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-full">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            บทบาทของกองทุนที่เกี่ยวข้องกับการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-8">
      <div class="cardCustom p-8">
        <div class="content2 mb-6">
          บทบาทของกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคมตามพระราชบัญญัติการพัฒนาดิจิทัลเพื่อเศรษฐ
          กิจและสังคม พ.ศ. 2560 มาตรา
          23ได้กำหนดให้จัดตั้งกองทุนขึ้นกองทุนหนึ่งในสำนักงานคณะกรรมการ
          ดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งชาติ เรียกว่า
          “กองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม” โดยมี
          วัตถุประสงค์เพื่อใช้จ่ายเกี่ยวกับการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
          และแผนยุทธศาสตร์การส่งเสริม เศรษฐกิจดิจิทัล
          จะเห็นได้ว่าการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคมตามแผนพัฒนาดิจิทัลเพื่อเศรษฐกิจ
          และสังคม
          และแผนยุทธศาสตร์การส่งเสริมเศรษฐกิจดิจิทัลนั้นค่อนข้างจะมีภารกิจที่ค่อนข้างมาก
          โดยบทบาท ของกองทุนนั้นจะแบ่งได้เป็น
        </div>

        <div class="mb-6">
          <div class="content1">
            (1)
            การผลักดันนวัตกรรมและเทคโนโลยีดิจิทัลที่ช่วยเพิ่มขีดความสามารถในการแข่งขันทางเศรษฐกิจ
            ของประเทศ
          </div>

          <div class="content1">
            (2)
            การสร้างนวัตกรรมและเทคโนโลยีดิจิทัลเพื่อยกระดับคุณภาพชีวิตของประชาชน
            และลดช่องว่าง ทางสังคม
          </div>

          <div class="content1">
            (3) ช่วยให้ความรู้และทักษะดิจิทัลที่เหมาะสมกับบุคลากรทุกกลุ่ม
          </div>

          <div class="content1">
            (4) พัฒนากระบวนทัศน์การทำงานและการให้บริการของภาครัฐ
            ด้วยเทคโนโลยีดิจิทัลและการใช้
          </div>

          <div class="content1">
            ประโยชน์จากข้อมูล เพื่อให้การปฏิบัติงานเกิดความโปร่งใส มีประสิทธิภาพ
            และประสิทธิผล
          </div>
        </div>

        <div class="mb-12">
          <div class="content2">การดำเนินงานตามนโยบาย</div>

          <div class="content1">
            การเปิดรับข้อเสนอโครงการ/กิจกรรม ปีงบประมาณ พ.ศ. ๒๕๖๓ ครั้งที่ ๑
          </div>

          <div class="content1" style="color: #AB84E8; text-decoration-line: underline;">
            <a href="https://www.onde.go.th/assets/portals/1/files/Announce63.pdf">
              https://www.onde.go.th/assets/portals/1/files/Announce63.pdf
            </a>
          </div>

          <div class="content1">
            การเปิดรับข้อเสนอโครงการ/กิจกรรม ปีงบประมาณ พ.ศ. ๒๕๖๒ งวดที่ ๑
          </div>

          <div class="content1" style="color: #AB84E8; text-decoration-line: underline;">
            <a href="https://www.onde.go.th/assets/portals/1/files/Documents/DEFund_Application_2562_1.pdf">
              https://www.onde.go.th/assets/portals/1/files/Documents/DEFund_Application_2562_1.pdf
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.content1 {
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}

.content2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}
</style>