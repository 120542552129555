<template>
  <div>
    <div v-if="aboutCount === 1">
      <aboutComponentOne />
    </div>

    <div v-else-if="aboutCount === 2">
      <aboutComponentTwo/>
    </div>

    <div v-else-if="aboutCount === 3">
      <aboutComponentThree/>
    </div>

    <div v-else-if="aboutCount === 4">
      <aboutComponentFour/>
    </div>

    <div v-else-if="aboutCount === 5">
      <aboutComponentFive/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import aboutComponentOne from "@/components/about/aboutComponentOne";
import aboutComponentTwo from '@/components/about/aboutComponentTwo';
import aboutComponentThree from '@/components/about/aboutComponentThree';
import aboutComponentFour from '@/components/about/aboutComponentFour';
import aboutComponentFive from '@/components/about/aboutComponentFive';
export default {
  components: {
    aboutComponentOne,
    aboutComponentTwo,
    aboutComponentThree,
    aboutComponentFour,
    aboutComponentFive,
  },
  mounted() {
    this.checkAbout();
  },
  computed: {
    ...mapState({
      aboutCount: (state) => state.about.aboutCount,
    }),
  },
  methods: {
    ...mapActions("about", ["actionsAboutCountAdd"]),
    checkAbout() {
      if (this.aboutCount > 1) {
        this.actionsAboutCountAdd(1);
      }
    },
  },
};
</script>