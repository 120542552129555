<template>
  <div style="margin-bottom: 80px;">
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-full">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            เกี่ยวกับกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-8">
      <div class="cardCustom p-8">
        <div class="content1 mb-6">
          ตามพระราชบัญญัติการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม พ.ศ. 2560 มาตรา
          23 ได้กำหนด
          ให้จัดตั้งกองทุนขึ้นกองทุนหนึ่งในสำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งชาติ
          เรียกว่า “กองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม”
          โดยมีวัตถุประสงค์เพื่อใช้จ่ายเกี่ยวกับการพัฒนาดิจิทัล
          เพื่อเศรษฐกิจและสังคมเพื่อให้เป็นไปตามนโยบายและแผนระดับชาติว่าด้วยการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
          และแผนยุทธศาสตร์การส่งเสริมเศรษฐกิจดิจิทัล
        </div>

        <div
          class="content1"
          style="color: #ab84e8; text-decoration-line: underline"
        >
          นโยบายและแผนระดับชาติว่าด้วยการพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
        </div>

        <div
          class="content1 mb-6"
          style="color: #ab84e8; text-decoration-line: underline"
        >
          แผนยุทธศาสตร์การส่งเสริมเศรษฐกิจดิจิทัล
        </div>

        <div class="content2">นโยบายการกำกับดูแลองค์การที่ดี</div>

        <div
          class="content1 mt-6"
          style="color: #ab84e8; text-decoration-line: underline"
        >
          คุณธรรมและความโปร่งใส
        </div>

        <div
          class="content1 mb-6"
          style="color: #ab84e8; text-decoration-line: underline"
        >
          ประกาศเจตจำนงการบริหารงานด้วยความซื่อสัตย์สุจริต
        </div>

        <div class="mb-6">
          <div class="content2">สถานที่การจัดส่งเอกสาร</div>
          <div class="content2">สำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐิจและสังคมแห่งชาติ</div>
          <div class="content2">๑๒๐ หมู่ ๓ ชั้น ๙ อาคารรัฐประศาสนภักดี</div>
          <div class="content2">ศูนย์ราชการเฉลิมพระเกียรติ ๘๐ พรรษา ๕ ธันวาคม ๒๕๕๐</div>
          <div class="content2">ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ ๑๐๒๑๐</div>
        </div>

        <div class="mb-12">
          <div class="content2">สถานที่ติดต่อ</div>
          <div class="content2">สำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐิจและสังคมแห่งชาติ</div>
          <div class="content2">( กองบริหารกองทุนฯ )</div>
          <div class="content2">ชั้น ๖ อาคารรัฐประศาสนภักดี</div>
          <div class="content2">ศูนย์ราชการเฉลิมพระเกียรติ ๘๐ พรรษา ๕ ธันวาคม ๒๕๕๐</div>
          <div class="content2">โทรศัพท์ ๐ ๒๑๔๒ ๗๕๗๓, ๐ ๒๑๔๒ ๗๕๗๘</div>
          <div class="content2">อีเมล <font color="#ab84e8" style="text-decoration-line: underline">http://defund@onde.go.th</font></div>
          <div class="content2">Facebook / Twitter defundth</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.content1 {
  text-indent: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}

.content2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}
</style>