<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-full">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            วัตถุประสงค์การใช้จ่ายเงินของกองทุนฯ
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="margin-bottom: 80px;">
      <div class="cardCustom" style="padding: 80px;">
        <div class="content2">
          วัตถุประสงค์ในการใช้จ่ายเงินกองทุนตาม มาตรา 26
          แห่งพระราชบัญญัติการพัฒนาดิจิทัล เพื่อเศรษฐกิจและสังคม พ.ศ. 2560
          มีวัตถุประสงค์
        </div>

        <div class="content2 mb-6">
          หรือแนวทางในการให้การสนับสนุนดังต่อไปนี้
        </div>

        <div class="mb-12">
          <div class="content1">
            1) ส่งเสริม สนับสนุน
            หรือให้ความช่วยเหลือหน่วยงานของรัฐและเอกชนหรือบุคคลทั่วไป
            ในการดำเนินการพัฒนาดิจิทัลเพื่อเศรษฐกิจและ
          </div>

          <div class="content1">
            สังคม ทั้งนี้การส่งเสริม สนับสนุน
            หรือให้ความช่วยเหลือดังกล่าวต้องมีวัตถุประสงค์เพื่อประโยชน์ต่อการให้บริการสาธารณะและไม่เป็นการ
          </div>

          <div class="content1">
            แสวงหากำไรโดยไม่เป็นการทำลายการแข่งขันอันพึงมีตามปกติวิสัยของกิจการภาคเอกชน
          </div>

          <div class="content1">
            2)
            ให้ทุนอุดหนุนการวิจัยและพัฒนาแก่หน่วยงานของรัฐและเอกชนหรือบุคคลทั่วไป
            ในเรื่องที่เกี่ยวกับการพัฒนาดิจิทัลเพื่อเศรษฐกิจและ
          </div>

          <div class="content1">
            สังคมโดยไม่ซ้ำซ้อนกับการวิจัยและพัฒนาที่มีอยู่
          </div>

          <div class="content1">
            3) จัดสรรเป็นเงินอุดหนุนแก่สำนักงาน ในการดำเนินงานตามอำนาจหน้าที่
            นอกเหนือจากที่ได้รับจากงบประมาณแผ่นดิน
          </div>

          <div class="content1">
            4) จัดสรรเป็นค่าใช้จ่ายในการดำเนินการของสำนักงานส่งเสริมเศรษฐกิจดิจิทัลตามที่คณะกรรมการบริหารกองทุนเห็นสมควร
          </div>

          <div class="content1">5) เป็นค่าใช้จ่ายในการบริหารกองทุน</div>

          <div class="content1">
            6) ค่าใช้จ่ายอื่น ๆ ตามหลักเกณฑ์ที่คณะกรรมการกำหนด
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.content1 {
  text-indent: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
}

.content2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
}
</style>