<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-full">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            โครงสร้างกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="margin-bottom: 80px;">
      <div class="cardCustom pt-20 pb-8 pr-8 pl-8" align='center'>
        <div class="mb-2">
            <img src="@/assets/mog/image60.svg" class="w-full h-full" style="max-width: 945px; ">
        </div>

        <div class="mb-2">
            <img src="@/assets/mog/image61.svg" class="w-full h-full" style="max-width: 945px; ">
        </div>

        <div class="mb-2">
            <img src="@/assets/mog/image62.svg" class="w-full h-full" style="max-width: 945px; ">
        </div>

        <div class="mb-2">
            <img src="@/assets/mog/image63.svg" class="w-full h-full" style="max-width: 945px; ">
        </div>

        <div class="" style="padding-bottom: 80px;">
            <img src="@/assets/mog/image64.svg" class="w-full h-full" style="max-width: 945px; ">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.content1 {
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
}

.content2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
}
</style>